<template>
    <div>
        <!-- <h1 class="text-subtitle-2 text-capitalize grey--text upper">Your Device Groups</h1> -->
<v-container fluid>

    
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
        <v-card-title>
            <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-weather-windy</v-icon>
            {{ $store.getters.getTextMap().productivity }}
        </v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> 

    <v-row justify-end  dense>
        <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/oee'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-sprout-outline</v-icon>
                        {{ $store.getters.getTextMap().oee }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>
        <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/manufacturing/checklist'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-multicast</v-icon>
                        {{ $store.getters.getTextMap().check_list }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>
        <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/manufacturing/logbooks'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-multicast</v-icon>
                        {{ $store.getters.getTextMap().manufacturing_logbooks }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>
        <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/manufacturing/batch'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-view-dashboard-outline</v-icon>
                        {{ $store.getters.getTextMap().batch_tracking }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>
        </v-row>
        </v-card>

        <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
        <v-card-title>
            <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-weather-windy</v-icon>
            {{ $store.getters.getTextMap().essentials }}
        </v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> 
        
    

    <v-row justify-end  dense>
        <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/machines'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-lightning-bolt-outline</v-icon>
                        {{ $store.getters.getTextMap().ems }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>
        <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/machines'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-water-opacity</v-icon>
                        {{ $store.getters.getTextMap().wms }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>
        
    </v-row>
    </v-card>
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
        <v-card-title>
            <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-account-hard-hat</v-icon>
            {{ $store.getters.getTextMap().ehs }}
        </v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> 
        <v-row justify-end  dense>
            <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/airquality'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-weather-windy</v-icon>
                        {{ $store.getters.getTextMap().air_quality }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>

        </v-row>
        </v-card>
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
        <v-card-title>
            <v-icon
            :isDark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().accentCode"
            class="mr-2"
            small
            >mdi-spa-outline</v-icon>
            {{ $store.getters.getTextMap().sustainability }}
        </v-card-title>
        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> 
        <v-row justify-end  dense>
            <v-col cols="auto" class="d-flex mr-1">
                <router-link
                :style="{
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }"
                :to="'/esg'" >
                    <v-card  :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background2ColorCode}" flat outlined class="px-2 mb-2">
                        <v-card-title>
                            <v-icon
                        :isDark="$store.getters.getColorPalette().isDark"
                        :color="$store.getters.getColorPalette().accentCode"
                        class="mr-2"
                        small
                        >mdi-spa-outline</v-icon>
                        {{ $store.getters.getTextMap().esg }}
                    </v-card-title>
                    </v-card>
                </router-link>
        </v-col>

        </v-row>
        </v-card>
</v-container>
    </div>
</template>

<script>
// import GroupList from '@/components/lists/GroupList'
// import ParamDummyList from '@/components/lists/ParamDummyList'
// import CreateGroupModal from '@/components/modals/CreateGroupModal'

export default {
    name:'Applications',
    components:{
        // GroupList,
        // ParamDummyList,
        // CreateGroupModal,
        // CreateEmailGroupModal,
        // NotificationGroupTable,
        // UserGroupTable,
        // MachineGroupTable,
        // ParamGroupTable,
        // CreateMachineGroupModal,
        // CreateParamGroupModal,
        // CreateUserGroupModal,
        // DepartmentTable
    },
    mounted() {
        document.title='Applications'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    }
}
</script>