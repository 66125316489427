var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-weather-windy")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().productivity)+" ")],1),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-row',{attrs:{"justify-end":"","dense":""}},[_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/oee'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-sprout-outline")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().oee)+" ")],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/manufacturing/checklist'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-multicast")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().check_list)+" ")],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/manufacturing/logbooks'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-multicast")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().manufacturing_logbooks)+" ")],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/manufacturing/batch'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-view-dashboard-outline")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().batch_tracking)+" ")],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-weather-windy")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().essentials)+" ")],1),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-row',{attrs:{"justify-end":"","dense":""}},[_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/machines'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-lightning-bolt-outline")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().ems)+" ")],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/machines'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-water-opacity")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().wms)+" ")],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-account-hard-hat")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().ehs)+" ")],1),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-row',{attrs:{"justify-end":"","dense":""}},[_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/airquality'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-weather-windy")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().air_quality)+" ")],1)],1)],1)],1)],1)],1),_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-spa-outline")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().sustainability)+" ")],1),_c('v-divider',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}}),_c('v-row',{attrs:{"justify-end":"","dense":""}},[_c('v-col',{staticClass:"d-flex mr-1",attrs:{"cols":"auto"}},[_c('router-link',{style:({
                color: 'inherit',
                display: 'flex',
                'justify-content': 'space-between',
                'align-item': 'center',
                'text-decoration': 'none',
                }),attrs:{"to":'/esg'}},[_c('v-card',{staticClass:"px-2 mb-2",style:({'border': '1px solid '+ _vm.$store.getters.getColorPalette().accentCode, 'background-color':_vm.$store.getters.getColorPalette().background2ColorCode}),attrs:{"dark":_vm.$store.getters.getColorPalette().isDark,"flat":"","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"isDark":_vm.$store.getters.getColorPalette().isDark,"color":_vm.$store.getters.getColorPalette().accentCode,"small":""}},[_vm._v("mdi-spa-outline")]),_vm._v(" "+_vm._s(_vm.$store.getters.getTextMap().esg)+" ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }